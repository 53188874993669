import React, { useEffect, useState } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui components
import { Box, Divider, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../../tab-panel";
import CustomTable from "../../../custom-table";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Heading5 from "../../../typography/heading-05";
import Heading6 from "../../../typography/heading-06";
import Body2 from "../../../typography/body-02";

// helper utils
import { formatCurrency } from "../../../../utils/format-currency";

// custom styles
import styles from "./index.module.scss";

const EligibleProductDrawer = ({ handleCloseDrawer, drawerData }) => {
	const productAdjustmentsColumn = [
		{ id: "description", label: "Description", minWidth: 310 },
		{ id: "rate", label: "Rate", minWidth: 175, align: "right" },
		{ id: "price", label: "Price/Points", minWidth: 175, align: "right" },
	];

	const productPaymentsColumn = [
		{ id: "item", label: "Item", minWidth: 310 },
		{ id: "payment", label: "Payment", minWidth: 175, align: "right" },
	];

	const [paymentsData, setPaymentsData] = useState([]);
	const [prepaidInterest, setPrepaidInterest] = useState(0);

	const [tabValue, setTabValue] = useState(1);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		console.log("55 drawer data:", drawerData);
		console.log("55 drawer row data:", drawerData?.rowData);
		console.log("55 drawer loan data:", drawerData?.loanData);

		let newArr = [];

		// const propertyTaxValue = drawerData?.loanData?.fees?.find(
		// 	(fee) => fee.feeType === "PropertyTaxes"
		// );

		newArr.push(
			{
				label: "Principal & Interest",
				value: drawerData?.rowData?.principalAndInterest || 0,
			},
			// { label: "Property Taxes", value: propertyTaxValue?.monthlyPayment || 0 },
			{
				label: "Property Taxes",
				value: drawerData?.loanData?.proposedRealEstateTaxesAmount || 0,
			},
			{
				label: "Homeowners Insurance",
				value: drawerData?.loanData?.proposedHazardInsuranceAmount || 0,
			},
			{ label: "Mortgage Insurance", value: 0 }
		);

		setPaymentsData(newArr);

		const prepaidInterestValue = drawerData?.loanData?.fees?.find(
			(fee) => fee.feeType === "PrepaidInterest"
		);

		setPrepaidInterest(prepaidInterestValue?.borPaidAmount || 0);
	}, [drawerData]);

	console.log("30 drawer data:", drawerData);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Loan product details and summary"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Heading5
					text={drawerData?.productDetails?.productName}
					extraClass={styles.greenText}
					fontType="semibold"
				/>

				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="eligible product tabs"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					{/* <Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Adjustments"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>
							</Stack>
						}
						{...a11yProps(0)}
					/> */}
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Rate & Payments"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{/* <Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Fees"
									fontType="semibold"
									extraClass={
										tabValue === 2 ? styles.activeTabText : styles.tabText
									}
								/>
							</Stack>
						}
						{...a11yProps(1)}
					/> */}
				</Tabs>

				{/* adjustments tab */}
				{/* <TabPanel
					index={0}
					value={tabValue}
					className={tabValue === 0 ? styles.tabPanelContainer : ""}
				>
					<CustomTable
						squaredBorder={true}
						maxHeight={true}
						tableType="eligible-product-adjustments"
						columnData={productAdjustmentsColumn}
						rowData={drawerData?.productDetails?.adjustments || []}
						extraData={drawerData || []}
					/>
				</TabPanel> */}

				{/* rate & payments tab */}
				<TabPanel
					index={1}
					value={tabValue}
					className={tabValue === 1 ? styles.tabPanelContainer : ""}
				>
					<Stack direction="column" className={styles.paymentsContainer}>
						<Stack
							direction="column"
							alignItems="flex-start"
							className={styles.cardContainer}
						>
							<Stack direction="row" className={styles.bottomContainer}>
								<Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="Rate" fontType="semibold" />
										<Body2
											text={Number(drawerData?.rowData?.rate)?.toFixed(3)}
											extraClass={styles.detailsValue}
										/>
									</Stack>
									<Box className={styles.dividerContainer}>
										<Divider className={styles.dividerLine} />
									</Box>
								</Stack>

								<Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="Payment" fontType="semibold" />
										<Body2
											text={formatCurrency.format(
												Number(drawerData?.rowData?.totalPayment) || 0
											)}
											extraClass={styles.detailsValue}
										/>
									</Stack>
									<Box className={styles.dividerContainer}>
										<Divider className={styles.dividerLine} />
									</Box>
								</Stack>
								<Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="APR" fontType="semibold" />
										<Body2
											text={Number(drawerData?.rowData?.apr)?.toFixed(3)}
											extraClass={styles.detailsValue}
										/>
									</Stack>
									<Box className={styles.dividerContainer}>
										<Divider className={styles.dividerLine} />
									</Box>
								</Stack>
								<Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="Loan Amount" fontType="semibold" />
										<Body2
											text={formatCurrency.format(
												Number(
													drawerData?.loanData?.borrowerRequestedLoanAmount
												) || 0
											)}
											extraClass={styles.detailsValue}
										/>
									</Stack>
									<Box className={styles.dividerContainer}>
										<Divider className={styles.dividerLine} />
									</Box>
								</Stack>
								{/* <Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="Fees" fontType="semibold" />
										<Body2 text={`fees`} extraClass={styles.detailsValue} />
									</Stack>
									<Box className={styles.dividerContainer}>
										<Divider className={styles.dividerLine} />
									</Box>
								</Stack> */}
								<Stack direction="row" className={styles.detailContainer}>
									<Stack direction="column" className={styles.detailDiv}>
										<Heading6 text="Loan Term" fontType="semibold" />
										<Body2
											text={`${drawerData?.parentProductDetails?.loanTerm}`}
											extraClass={styles.detailsValue}
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>

						<Stack className={styles.tableContainer}>
							<Heading6
								text="Monthly Payment"
								fontType="semibold"
								extraClass={styles.tableHeader}
							/>
							<CustomTable
								tableType="eligible-product-payments"
								columnData={productPaymentsColumn}
								rowData={paymentsData || []}
							/>
						</Stack>
					</Stack>
				</TabPanel>

				{/* fees tab */}
				{/* <TabPanel
					index={2}
					value={tabValue}
					className={tabValue === 2 ? styles.tabPanelContainer : ""}
				>
					<Stack direction="column" className={styles.feesContainer}>
						<Stack direction="column" className={styles.sheetContainer}>
							<Stack direction="row" className={styles.headerRow}>
								<Stack className={styles.rowCellLeft}>
									<Heading5
										text="Fees"
										fontType="semibold"
										extraClass={styles.rowText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading5
										text="Amount"
										fontType="semibold"
										extraClass={styles.rowText}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sheetRow1}>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="CLOSING COSTS/FEES"
										fontType="bold"
										extraClass={styles.rowText}
									/>
								</Stack>
							</Stack>

							<Stack
								direction="row"
								className={styles.sheetRow2 + " " + styles.greyRow}
							>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="LENDER CHARGES"
										fontType="bold"
										extraClass={styles.rowSubtext}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sheetRow3}>
								<Stack className={styles.rowCellLeft}>
									<Heading6
										text="Closing cost"
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text={formatCurrency.format(
											Number(drawerData?.rowData?.closingCost)
										)}
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sectionTotalRow}>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="TOTAL CLOSING COSTS/FEES"
										fontType="bold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text={formatCurrency.format(
											Number(drawerData?.rowData?.closingCost)
										)}
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>
						</Stack>

						<Stack direction="column" className={styles.sheetContainer}>
							<Stack direction="row" className={styles.sheetRow1}>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="PREPAID AND IMPOUND AMOUNTS"
										fontType="bold"
										extraClass={styles.rowText}
									/>
								</Stack>
							</Stack>

							<Stack
								direction="row"
								className={styles.sheetRow2 + " " + styles.greyRow}
							>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="PREPAID AMOUNTS"
										fontType="bold"
										extraClass={styles.rowText}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sheetRow3}>
								<Stack className={styles.rowCellLeft}>
									<Heading6
										text="Prepaid interest"
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text={formatCurrency.format(Number(prepaidInterest) || 0)}
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>

							<Stack
								direction="row"
								className={styles.sheetRow2 + " " + styles.greyRow}
							>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="IMPOUND AMOUNTS"
										fontType="bold"
										extraClass={styles.rowSubtext}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sheetRow3}>
								<Stack className={styles.rowCellLeft}>
									<Heading6
										text="Description"
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text="Amount"
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.sectionTotalRow}>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="TOTAL PREPAID & IMPOUND AMOUNTS"
										fontType="bold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text="Amount"
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.totalRow}>
								<Stack className={styles.rowCellLeft}>
									<Body3
										text="TOTAL CLOSING AND PREPAID ITEMS"
										fontType="bold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
								<Stack className={styles.rowCellRight}>
									<Heading6
										text={formatCurrency.format(
											Number(drawerData?.rowData?.closingCost)
										)}
										fontType="semibold"
										extraClass={styles.rowItemText}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</TabPanel> */}
			</Stack>
		</Stack>
	);
};

export default EligibleProductDrawer;
